import { render, staticRenderFns } from "./QQButton.vue?vue&type=template&id=35c7c882&scoped=true&"
import script from "./QQButton.vue?vue&type=script&lang=js&"
export * from "./QQButton.vue?vue&type=script&lang=js&"
import style0 from "./QQButton.vue?vue&type=style&index=0&id=35c7c882&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35c7c882",
  null
  
)


import QBtn from 'quasar/src/components/btn/QBtn.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QList,QItem,QItemSection,QAvatar,QItemLabel});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('35c7c882')) {
      api.createRecord('35c7c882', component.options)
    } else {
      api.reload('35c7c882', component.options)
    }
    module.hot.accept("./QQButton.vue?vue&type=template&id=35c7c882&scoped=true&", function () {
      api.rerender('35c7c882', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/QQButton.vue"
export default component.exports