var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-btn",
    {
      staticClass: "rounded-borders no-border q-ma-xs",
      class: "btn-fixed-width-" + _vm.size,
      attrs: {
        color: _vm.color,
        size: _vm.size,
        disable: _vm.disable,
        click: _vm.onclick,
      },
    },
    [
      _c(
        "q-list",
        [
          _c(
            "q-item",
            [
              _vm.icon_align === "left"
                ? _c(
                    "q-item-section",
                    { attrs: { avatar: "" } },
                    [
                      _vm.icon !== ""
                        ? _c("q-avatar", {
                            attrs: {
                              color: _vm.icon_color,
                              "text-color": "black",
                              icon: _vm.icon,
                              size: _vm.size,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "q-item-section",
                [_c("q-item-label", [_vm._v(_vm._s(_vm.label))])],
                1
              ),
              _vm.icon_align === "right"
                ? _c(
                    "q-item-section",
                    { staticClass: "text-right", attrs: { avatar: "" } },
                    [
                      _vm.icon !== ""
                        ? _c("q-avatar", {
                            attrs: {
                              color: _vm.icon_color,
                              "text-color": "black",
                              icon: _vm.icon,
                              size: _vm.size,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }